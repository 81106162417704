import React, { useState,useEffect } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';
import kwesforms from 'kwesforms';

const Footer = () => {
  useEffect(() => {
    kwesforms.init();
  },[]);
  
  

  return (
    <>
      <h2 className="head-text">Get In Contact With Me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:g@sofo.space" className="p-text ">g@sofo.space</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (505) 310-8332" className="p-text">+1 (505) 310-8332</a>
        </div>
      </div>
      <div className="app__footer-form">
      <form className="kwes-form " action="https://kwesforms.com/api/foreign/forms/I1Wb1ToRcCaFv1v3TlNz">
        
          <div className="app__flex">
            
          <input placeholder="Your Name"type="text" className="p-text" name="name" data-kw-rules="required|min:2|max:40"/>
          </div>
          <div className="app__flex">
            
          <input placeholder="Your Email" type="text" className="p-text" name="email" data-kw-rules="required|email"/>
          </div>
          <div className="app__flex">
            
          <textarea
              className="p-text"
              placeholder="Your Message"
              data-kw-rules="required|min:2|max:255"
              name="message"
              
            />
          </div>
          <button type="submit" className="p-text">Submit</button>
          
      </form>
      </div>
      
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);