import React from 'react';
import { BsTwitter } from 'react-icons/bs';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <a href='https://twitter.com/sofosiator'><div>
      <BsTwitter />
    </div></a>
    
    <a href='https://www.linkedin.com/in/dev-khalsa/'>
    <div>
      <FaLinkedin />
    </div></a>
    <a href='https://github.com/good-dude-dev '>
    <div>
      <FaGithub />
    </div>
    </a>
  </div>
);

export default SocialMedia;